<style lang="less" scoped>

</style>
<template>
  <div>错题记录</div>
</template>
<script>
export default {
  
}
</script>